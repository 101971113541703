<script setup lang="ts">
import { IconButton, Skeleton } from '@laam/ui/base';
import { PhCaretLeft } from '@phosphor-icons/vue';
import SearchButton from '~/components/SearchButton.vue';
import type { BannerReasons } from '../TopBanner.vue';
import { type SeoData } from '~/data/entities';
import DeliveryOptions from '../delivery-options/DeliveryOptions.vue';

const { storeType } = useGlobalStore();

interface Prop {
	static?: boolean;
	showBack?: boolean;
	itemsCount?: number;
	data?: Pick<SeoData, 'title' | 'subtitle' | 'description'>;
}

withDefaults(defineProps<Prop>(), {
	static: false,
	showBack: true,
	data: undefined,
	itemsCount: undefined,
});

// TODO: make sense of it
// useHead(() => ({
// 	titleTemplate: `${props.data?.title} | LAAM`,
// 	link: [
// 		{
// 			rel: 'canonical',
// 			href: `https://laam.pk${route.fullPath.toLowerCase()}`,
// 		},
// 	],
// }));

const visible = inject('headerVisible') as Ref<boolean>;
const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
const GlobalStore = useGlobalStore();
const { deviceType } = useDeviceType();
const deliveryOptionsExp = inject('deliveryOptionsExp') as Ref<boolean>;
</script>
<template>
	<div
		:data-store-type="storeType"
		:data-visible="visible"
		:data-top-banner="!!topBannerReason"
		class="plp--header sticky top-0 left-0 right-0 z-10 transition-all"
		:class="{
			'!hidden': GlobalStore.storeType === 'OCTANE' && deviceType === 'mobile',
		}"
	>
		<div
			class="mobile_header py-md lg:py-3xl px-sm z-10 flex items-center justify-between bg-white border-b border-gray-200"
		>
			<div class="grow gap-md flex">
				<IconButton
					size="nav-button"
					class="lg:hidden"
					variant="icon"
					@click="$router.back()"
				>
					<PhCaretLeft />
				</IconButton>

				<div class="flex flex-col justify-center">
					<h1
						class="text-md lg:text-2xl font-semibold text-gray-900"
						data-testid="plp-title"
					>
						{{ data?.title }}
					</h1>
					<p
						v-if="itemsCount !== undefined && itemsCount >= 0"
						class="line-clamp-1 lg:text-sm text-xs text-gray-600 capitalize"
					>
						{{ new Intl.NumberFormat().format(itemsCount!) + ' items' }}
					</p>
					<Skeleton v-else class="w-12xl h-4xl mt-xs bg-gray-100 rounded" />
				</div>
			</div>
			<div class="gap-md lg:hidden flex">
				<DeliveryOptions v-if="deliveryOptionsExp" />
				<SearchButton />
				<CartButton />
			</div>
		</div>
	</div>
</template>

<style scoped>
.plp--header[data-store-type='MARKETPLACE'][data-visible='false'] {
	top: calc(
		0 - var(--plp-header-height-mobile) - var(--laam-shipping-banner-height)
	) !important;

	@media (min-width: 768px) {
		top: calc(
			0 - var(--plp-header-height) - var(--laam-topbar-height) -
				var(--laam-shipping-banner-height)
		) !important;
	}
}
.plp--header[data-store-type='MARKETPLACE'][data-visible='true'][data-top-banner='true'] {
	top: var(--laam-shipping-banner-height);
	@media (min-width: 768px) {
		top: calc(var(--laam-topbar-height) + var(--laam-shipping-banner-height));
	}
}
.plp--header[data-store-type='MARKETPLACE'][data-visible='true'][data-top-banner='false'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--laam-topbar-height);
	}
}
.plp--header[data-store-type='OCTANE'][data-visible='false'] {
	top: calc(0 - var(--plp-header-height-mobile));
	@media (min-width: 768px) {
		top: calc(0 - var(--plp-header-height) - var(--octane-topbar-height));
	}
}

.plp--header[data-store-type='OCTANE'][data-visible='true'] {
	top: 0;
	@media (min-width: 768px) {
		top: var(--octane-topbar-height);
	}
}
</style>
