import { useQuery } from '@tanstack/vue-query';
import { Type, type Static } from '@sinclair/typebox';
import { validatorFactoryArray } from '@laam/lib/validator';

const nodesSchema = Type.Array(
	Type.Object({
		id: Type.Number(),
		title: Type.String(),
		parent_id: Type.Number(),
		logo: Type.String(),
		image: Type.String(),
		valhalla_score: Type.Number(),
		product_count: Type.Number(),
		node_level: Type.Number(),
		href: Type.String(),
		path: Type.Optional(Type.Array(Type.String())),
	}),
);
export type NodesSchema = Static<typeof nodesSchema>;

const nodesValidator = validatorFactoryArray<NodesSchema>(nodesSchema);

export const useNodesQuery = (
	entity: string,
	handle: Ref<string>,
	enabledOverride: Ref<boolean> = ref(true),
) => {
	const enabled = computed(
		() => !!enabledOverride?.value && handle.value !== '',
	);
	const { data, isLoading, error, isFetched } = useQuery<NodesSchema>({
		queryKey: ['entity', entity, 'handle', handle],
		gcTime: Infinity,
		retry: 3,
		staleTime: Infinity,
		enabled,
		queryFn: async () => {
			let handleType = '';
			if (entity === 'nodes') {
				handleType = 'node_id';
			} else if (entity === 'brands') {
				handleType = 'brand_handle';
			} else if (entity === 'drops') {
				handleType = 'drop_handle';
			}
			const api = createApiInstance();
			const response = await api.get(
				`/v1/feed?entity=${entity}&${handleType}=${handle.value}`,
			);
			if (response.status === 200) {
				const body = await response.data;
				try {
					return nodesValidator.verify(body);
				} catch (err) {
					console.log(err);
					throw err;
				}
			}
			throw 'invalid feed nodes response code';
		},
	});
	return { data, isLoading, error, isFetched };
};
