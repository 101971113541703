<script setup lang="ts">
import ProductList from '~/components/plp/ProductList.vue';
import FilterBar from '~/components/filter-bar/index.vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';
import {
	fetchBrandProducts,
	useProductsCount,
	useProductsList,
} from '~/data/products';
import { useSeoData } from '~/data/entities';
import { useDebounceFn } from '@vueuse/core';
import PlpHeader from '~/components/plp/Header.vue';
import Description from '~/components/plp/Description.vue';
import { useNodesQuery } from '~/data/nodes';
import { Separator } from 'radix-vue';

import NodesBar from '~/components/plp/NodesBar.vue';
const { $cathodeClient } = useNuxtApp();
const { $eventClient } = useNuxtApp();

const route = useRoute();

const handle = ref(route.params.handle as string);
const filters = ref<string>(route.fullPath.split('?')[1] || '');

const { storeType, store } = useGlobalStore();

const enabled = ref(true);

const { data: seo, suspense, error } = useSeoData(handle, enabled);
const { data: itemsCount } = useProductsCount(handle, 'brands', filters);

const disableSignReload = ref(false);
const toggleDisableSignReload = () => {
	disableSignReload.value = !disableSignReload.value;
};

provide('toggleDisableSignReload', toggleDisableSignReload);

watch(disableSignReload, (newValue) => {
	if (newValue) {
		enabled.value = false;
	} else {
		enabled.value = true;
	}
});

watch(seo, (seoData) => {
	if (!seoData) return;
	if (Object.keys(seoData || {}).length) {
		$eventClient.sendEvent('view-item-list', {
			id: seoData.id,
			title: seoData.title,
			productCount: seoData.subtitle,
		});
	}
});

onServerPrefetch(async () => {
	await suspense();
});

await suspense();

if (!seo.value || error.value) {
	throw createError({
		statusCode: 404,
		message: 'Brand not found ' + route.fullPath,
		stack: undefined,
		fatal: true,
	});
}
const {
	data,
	isLoading,
	hasNextPage,
	fetchNextPage,
	isFetchingNextPage,
	error: productListError,
} = useProductsList({
	queryKey: ['brands', handle, filters],
	queryFn: ({ pageParam: cursor }) => {
		return fetchBrandProducts(handle.value, cursor as string, filters.value);
	},
	enabledValue: enabled,
});

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	filters.value = route.fullPath.split('?')[1] || '';
	handle.value = route.params.handle as string;
}, 200);

const { data: nodesData } = useNodesQuery('brands', handle);

watch(route, (route) => {
	if (!route.name!.toString().includes('brands')) {
		return;
	}
	debounce(route);
});

onMounted(() => {
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		page: 'PLP',
		statusCode: 200,
		referrer: document.referrer,
		collection_handle: route.params.handle as string,
	});
});

const nodesBarData = computed(() => {
	if (nodesData.value && nodesData.value?.length) {
		return nodesData.value.filter((node) => node.node_level === 4);
	}
	return [];
});

watch(isLoading, (isLoading) => {
	if (!isLoading && productListError.value) {
		throw createError({
			statusCode: 404,
			message: 'Failed to load products',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
});

useHead({
	title: dropMetaTitle(seo.value!, store.id, storeType!, store.name!),
	titleTemplate: null,
});
useSeoMeta({
	ogTitle: dropMetaTitle(seo.value!, store.id, storeType!, store.name!),
	description: dropMetaDesc(seo.value!, store.id, storeType!, store.name!),
	ogDescription: dropMetaDesc(seo.value!, store.id, storeType!, store.name!),
});
</script>
<template>
	<div>
		<PlpHeader :data="seo" :items-count="itemsCount" />

		<ClientOnly>
			<div
				v-if="nodesBarData && nodesBarData.length > 0"
				class="flex flex-col gap-3xl border-b border-gray-200 py-3xl"
			>
				<NodesBar v-if="nodesBarData.length" :feed-nodes="nodesBarData" />
			</div>
			<FilterBar :handle="handle" />

			<ProductList
				:infinite-loader="true"
				:fetch-next-page="fetchNextPage"
				:is-loading="isLoading"
				:data="data"
				:has-next-page="hasNextPage"
				:is-fetching-next-page="isFetchingNextPage"
			/>
			<template #fallback>
				<FilterbarSkeleton />
				<ProductListSkeleton />
			</template>
		</ClientOnly>

		<div
			v-if="!!seo?.description && storeType === STORE_TYPE.MARKETPLACE"
			class="w-full"
		>
			<Separator class="seo-description-separator" />
			<Description :description="seo.description" :heading="seo.title" />
		</div>
	</div>
</template>

<style scoped>
.seo-description-separator {
	@apply !bg-gray-100 h-xl mt-5xl;
}
</style>
